<template>
    <div>
        <div class="row mb-3">
            <div class="col-md-4 col-12">
                <div class="row align-items-center">
                    <div class="col-md-auto col-12">
                        <h6 class="bold mb-0">Tìm theo Link:</h6>
                    </div>
                    <div class="col-md col-12">
                        <input
                            @change="getListJobOrder()"
                            v-on:keydown.enter="getListJobOrder()"
                            class="form-control input-light"
                            type="text"
                            placeholder="Nhập ID cần tìm"
                            required=""
                            v-model="link"
                        />
                    </div>
                </div>
            </div>
            <div class="col-md-4 col-12">
                <div class="row align-items-center">
                    <div class="col-md-auto col-12">
                        <h6 class="bold mb-0">Số nhật ký hiển thị tối đa:</h6>
                    </div>
                    <div class="col-md col-12">
                        <select @change="getListJobOrder()" v-model="limit" class="custom-select select-light">
                            <option value="50">50</option>
                            <option value="100">100</option>
                            <option value="200">200</option>
                            <option value="500">500</option>
                            <option value="1000">1000</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
        <Datatable stt="true" :columns="columnsTableLogs" :rows="data">
            <th slot="thead-tr-o" class="text-center" style="vertical-align: middle">Thao Tác</th>
            <td slot="tbody-tr-o" slot-scope="props" class="text-center" style="vertical-align: middle">
                <div class="btn-group dropright">
                    <button
                        type="button"
                        class="btn bg-primary text-white btn-xs dropdown-toggle"
                        data-toggle="dropdown"
                        aria-expanded="false"
                    >
                        Thao Tác
                    </button>
                    <div class="dropdown-menu">
                        <div class="dropdown-item" v-if="props.row.status != 2">
                            <button
                                @click="reCheckAdsById(props.row.id, 'confirm')"
                                class="w-100 btn bg-info text-white btn-xs"
                            >
                                <i class="far fa-check-circle"></i> Duyệt Chạy
                            </button>
                        </div>
                        <div class="dropdown-item" v-if="props.row.status != 2">
                            <button
                                @click="reCheckAdsById(props.row.id, 'hidden')"
                                class="w-100 btn bg-danger text-white btn-xs"
                            >
                                <i class="far fa-trash-alt"></i> Tạm Dừng
                            </button>
                        </div>
                        <div class="dropdown-item" v-if="props.row.is_removed != 1">
                            <button
                                @click="reCheckAdsById(props.row.id, 'remove')"
                                class="w-100 btn bg-danger text-white btn-xs"
                            >
                                <i class="far fa-trash-alt"></i> Hủy Đơn
                            </button>
                        </div>
                        <div class="dropdown-item">
                            <button
                                @click="rowShowReportClick(props.row)"
                                class="w-100 btn bg-warning text-white btn-xs"
                            >
                                <i class="fas fa-info-circle"></i> Xem báo cáo
                            </button>
                        </div>
                    </div>
                </div>
            </td>
        </Datatable>
        <div
            class="modal fade"
            id="reportDetailModal"
            tabindex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
        >
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Báo cáo kết quả chạy chiến dịch</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <code>
                            <Datatable
                                class="box-shadow"
                                :title="'Lịch sử'"
                                :stt="true"
                                :columns="columnsTableReportLogs"
                                :rows="reports"
                            />
                        </code>
                    </div>
                    <div class="modal-footer">
                        <button type="button" :class="['btn btn-sm bg-warning text-white px-4']" data-dismiss="modal">
                            Đóng
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { catchError, buildParamQuery } from "@/helpers"
import { getLogsOrder, getListReportByAdsId, reCheckAds } from "@/api/services/traffics"
import Datatable from "@/components/datatable/datatable"

const COLUMNS_TABLE = [
    { choose: true, label: "Id", field: "id", admin: true },
    { choose: true, label: "Image", field: "image", use_v_viewer: true },
    { choose: true, label: "Username", field: "username" },
    { choose: true, label: "Từ khóa", field: "keywords" },
    {
        choose: true,
        label: "Traffic/ngày",
        field: "daily_traffic",
        numeric: true
    },
    {
        choose: true,
        label: "Tổng traffic",
        field: "quantity",
        numeric: true
    },
    { choose: true, label: "Tổng tiền", field: "total", numeric: true },
    {
        choose: true,
        label: "Đã chạy",
        field: "count_is_run",
        numeric: true
    },
    { choose: true, label: "SĐT", field: "phone", admin: true },
    { choose: true, label: "Ref", field: "referral", admin: true },
    { choose: true, label: "Khởi tạo", field: "created_at", timeago: true },
    {
        choose: true,
        label: "Trạng thái",
        field: "status_text_html",
        html: true
    }
]

export default {
    name: "traffic-logs",
    components: {
        Datatable
    },
    props: {
        panel: String,
        type: String
    },
    data() {
        return {
            link: null,
            limit: 50,
            data: [],
            reports: [],
            columnsTableReportLogs: [
                { choose: true, label: "Từ Khóa", field: "keyword" },
                { choose: true, label: "Ip", field: "ip" },
                { choose: true, label: "User Agent", field: "user_agent" },
                { choose: true, label: "Ngày Thực Hiện", field: "created_at" }
            ]
        }
    },
    computed: {
        currency() {
            return this.$store.state.site.site.currency
        },
        columnsTableLogs() {
            const defaultColumns = [...COLUMNS_TABLE]
            const googleTable = [
                {
                    choose: true,
                    label: "Link",
                    field: "link",
                    link_link: true,
                    link: "link"
                }
            ]
            const backlinkTable = [
                {
                    choose: true,
                    label: "Link BackLink",
                    field: "search_link",
                    link_link: true,
                    link: "search_link"
                },
                {
                    choose: true,
                    label: "Link Đích",
                    field: "link",
                    link_link: true,
                    link: "link"
                }
            ]
            if (this.type === "google") {
                defaultColumns.splice(3, 0, ...googleTable)
            } else {
                defaultColumns.splice(3, 0, ...backlinkTable)
            }
            return defaultColumns
        },
        user() {
            return this.$store.state.user.user
        }
    },
    watch: {
        panel(value) {
            if (value === "logs") {
                this.getListJobOrder()
            }
        }
    },
    methods: {
        async getListJobOrder() {
            let params = {
                limit: this.limit,
                link: this.link,
                type: this.type
            }
            const queries = buildParamQuery(params)
            const response = await getLogsOrder(queries)
            if (!response) {
                this.$toastr.error("Đã có lỗi xảy ra, vui lòng thử lại sau")
            }
            if (response.success) {
                this.data =
                    response.data?.map(item => {
                        const total = item.price_per * item.quantity_origin
                        const status_text_html =
                            item.object_not_exist || item.is_hidden || (item.is_refund && item.count_refund > 0)
                                ? `<span class="py-1 badge badge-danger"><i class="material-icons icon-log">info</i>${item.status_message}</span>`
                                : item.status !== 2
                                ? `<span class="py-1 badge badge-yellow"><i class="material-icons icon-log">autorenew</i>${item.status_message}</span>`
                                : `<span class="py-1 badge badge-success"><i class="material-icons icon-log">beenhere</i>${item.status_message}</span>`
                        return {
                            ...item,
                            total,
                            status_text_html
                        }
                    }) || []
            } else {
                this.$toastr.error(catchError(response))
            }
        },
        async rowShowReportClick(row) {
            await this.getListReportByAdsId(row.id)
            $("#reportDetailModal").modal("show")
        },
        async getListReportByAdsId(adsId) {
            this.reports = []
            let params = `?limit=10000&ads_id=${adsId}`
            let data = await getListReportByAdsId(params)
            if (data.data) {
                this.reports = data.data.map(order => {
                    order.color = order.status === 0 ? true : false
                    return order
                })
            }
        },
        async reCheckAdsById(adsId, action = "confirm") {
            const body = { ads_id: adsId, action }
            const response = await reCheckAds(body)
            if (!response) {
                this.$toastr.error("Đã có lỗi xảy ra, vui lòng thử lại sau")
                return
            }
            if (response.success) {
                this.$toastr.success(response.message || "Thao tác thành công !")
            } else {
                this.$toastr.error(catchError(response))
            }
        }
    }
}
</script>
