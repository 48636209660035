<template>
    <div>
        <h4 class="bold text-uppercase">{{ $route.name }}</h4>
        <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item">
                <a class="nav-link" :class="panel === 'order' ? 'show active' : ''" @click="handleShowPanel('order')">
                    Tạo tiến trình
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link" :class="panel === 'logs' ? 'show active' : ''" @click="handleShowPanel('logs')">
                    Nhật ký Order
                </a>
            </li>
        </ul>
        <div class="tab-content mt-3" id="myTabContent">
            <div class="tab-pane fade" :class="panel === 'order' ? 'show active' : ''">
                <div class="row">
                    <div class="form-group col-12">
                        <p>
                            Hiện tại chúng tôi có hơn <b>250.000 thành viên</b>, quý khách có thể dùng để đẩy SEO từ
                            khóa lên Top hoặc tiếp cận người dùng tăng độ nhận diện cho doanh nghiệp của mình.
                        </p>
                        <p class="text-danger font-bold mb-0">
                            <strong>Lưu Ý</strong> <br />
                            - Nghiêm cấm tạo đơn với các trang website vi phạm pháp luật, chính trị, đồi trụy, cờ bạc
                            .... Nếu cố tính sẽ bị HỦY JOBS KHÔNG TOÀN TIỀN và có thể bị band tài khoản khỏi hệ thống và
                            chịu hoàn toàn trách nhiệm trước pháp luật <br />
                            - Hệ thống 99% là người việt thật và đang sinh sống tại việt nam thực hiện nhiệm vụ <br />
                            - Các jobs số lượng lớn chạy không đủ sẽ được hủy và hoàn tiền còn thừa
                        </p>
                    </div>

                    <template v-if="type === 'redirect'">
                        <div class="form-group col-12">
                            <h6 for="search_link" class="bold mb-0">
                                Trang web cần redirect trỏ tới (nhập chính xác url)
                            </h6>
                            <div>
                                <input
                                    v-model="url"
                                    type="url"
                                    class="form-control form-control-md input-gray"
                                    id="url"
                                    placeholder="Trang web đích chứa backlink"
                                    required
                                />
                            </div>
                        </div>
                    </template>

                    <template v-if="type === 'backlink'">
                        <div class="form-group col-12">
                            <h6 for="search_link" class="bold mb-0">
                                Trang web đích chứa backlink (nhập chính xác url)
                            </h6>
                            <div>
                                <input
                                    v-model="search_link"
                                    type="url"
                                    class="form-control form-control-md input-gray"
                                    id="search_link"
                                    placeholder="Trang web đích chứa backlink"
                                    required
                                />
                            </div>
                        </div>
                        <div class="form-group col-12">
                            <h6 for="keywords" class="bold mb-0">Text link để user tìm kiếm click vào !</h6>
                            <textarea
                                v-model="keywords"
                                class="form-control form-control-md input-gray"
                                name="keywords"
                                id="keywords"
                                rows="5"
                                placeholder="Có thể có nhiều text link ví dụ web đó bạn đặt 2 backlink thì có thể nhập 2 từ khóa đó để user tìm kiếm"
                                required
                            />
                        </div>
                        <div class="form-group col-12">
                            <h6 for="url" class="bold mb-0">Trang web cần backlink trỏ tới (nhập chính xác url)</h6>
                            <div>
                                <input
                                    v-model="url"
                                    type="url"
                                    class="form-control form-control-md input-gray"
                                    id="url"
                                    placeholder="Trang web cần backlink trỏ tới"
                                    required
                                />
                            </div>
                        </div>
                    </template>

                    <template v-if="type === 'google'">
                        <div class="form-group col-12">
                            <h6 class="bold mb-0">
                                URL đích (nhập chính xác url)
                            </h6>
                            <div>
                                <input
                                    v-model="url"
                                    type="url"
                                    class="form-control input light"
                                    id="url"
                                    placeholder="Nhập url đích muốn chạy traffic"
                                    required
                                />
                            </div>
                        </div>
                        <div class="form-group col-12">
                            <h6 for="keywords" class="bold mb-0">Từ khóa (nằm ở trang 1-5 để user tìm thấy)</h6>
                            <textarea
                                v-model="keywords"
                                class="form-control input light"
                                name="keywords"
                                id="keywords"
                                rows="5"
                                placeholder="Nhập mỗi từ khóa trên 1 dòng"
                                required
                            />
                        </div>
                    </template>

                    <div class="form-group col-12" v-if="type === 'google' || type === 'backlink'">
                        <label class="w-100" for="image-upload">
                            <h6 class="d-inline bold mb-0">Thêm hình ảnh mô tả</h6>
                            <span>
                                {{ files.length > -1 ? "(Click vào ảnh để xóa ảnh)" : "" }}
                            </span>
                            <span class="bg-info text-white float-right px-2 py-1 rounded">
                                <i class="fas fa-cloud-upload-alt"></i> Tải ảnh lên
                            </span>
                        </label>
                        <input type="file" id="image-upload" class="d-none" ref="files" @change="handleFilesUpload()" />
                        <div class="d-flex flex-nowrap overflow-auto">
                            <span
                                v-for="(file, index) in files"
                                :key="index"
                                style="width:100px;height:100px"
                                class="col-auto px-1 flex-nowrap"
                            >
                                <img
                                    :src="file.url"
                                    style="object-fit:cover;width:100%;height:100%;border-radius: 10px"
                                    :alt="'user' + index"
                                    @click="removeFile(index)"
                                />
                            </span>
                        </div>
                    </div>

                    <div class="form-group col-sm-6">
                        <h6 class="bold mb-0">Số traffic/ngày (tối thiểu 30)</h6>
                        <div>
                            <input
                                :value="theFormat(quantityPerDay)"
                                @blur="handleBlurQuantityPerDay"
                                @keyup="handleChangeQuantityPerDay"
                                type="text"
                                class="form-control bg-gray border-0 form-control-md"
                                style="border-radius: 10px !important"
                                required
                            />
                        </div>
                    </div>

                    <div class="form-group col-sm-6">
                        <h6 class="bold mb-0">Tổng số traffic mua (mua tối thiểu 500)</h6>
                        <div>
                            <input
                                :value="theFormat(quantity)"
                                @blur="handleBlurQuantity"
                                @keyup="handleChangeQuantity"
                                type="text"
                                class="form-control bg-gray border-0 form-control-md"
                                style="border-radius: 10px !important"
                                required
                            />
                        </div>
                    </div>

                    <div class="form-group col-sm-6">
                        <h6 class="bold mb-0">Gói onsite:</h6>
                        <div>
                            <select
                                v-model="timeOnSite"
                                class="form-control font-bold custom-select input-gray"
                                name="option"
                            >
                                <option :value="null">-- Lựa chọn --</option>
                                <option v-for="item in packages" :key="item.name" :value="item.package_name">{{
                                    item.name
                                }}</option>
                            </select>
                        </div>
                    </div>

                    <div class="form-group col-sm-6">
                        <h6 class="bold mb-0">Giá 1 traffic</h6>
                        <div>
                            <input
                                :value="theFormat(price)"
                                type="text"
                                class="form-control bg-gray border-0 form-control-md"
                                style="border-radius: 10px !important"
                                readonly
                            />
                        </div>
                    </div>

                    <div class="form-group col-sm-6">
                        <h6 class="bold mb-0">Mã giới thiệu (Không bắt buộc)</h6>
                        <div>
                            <input
                                v-model="referral"
                                type="referral"
                                class="form-control form-control-md input-gray"
                                id="referral"
                                placeholder="Nhập mã giới thiệu để được giảm giá"
                                required
                            />
                        </div>
                    </div>

                    <div class="form-group col-sm-6">
                        <h6 class="bold mb-0">
                            Nhận số điện thoại (Không bắt buộc - Dùng để chúng tôi liên lạc khi đặt sai mã)
                        </h6>
                        <div>
                            <input
                                v-model="phone"
                                type="phone"
                                class="form-control form-control-md input-gray"
                                id="phone"
                                placeholder="Nhận số điện thoại để nhận hỗ trợ !"
                                required
                            />
                        </div>
                    </div>

                    <TrafficsNote />

                    <div class="form-group col-12 text-center bold">
                        <div class="card card-info">
                            <div class="card-body">
                                <h5 class="font-bold">
                                    Tổng:
                                    <span class="bold"
                                        ><number
                                            ref="number1"
                                            :from="0"
                                            :to="totalPrice"
                                            :format="theFormat"
                                            :duration="1"
                                            :delay="0"
                                            easing="Power1.easeOut"
                                        />
                                        {{ currency }}</span
                                    >
                                </h5>
                                <h6 class="mb-0">
                                    Bạn sẽ buff <span class="bold">{{ theFormat(quantity) }} traffic</span> với giá
                                    <span class="green bold">{{ theFormat(price) }}</span>
                                    {{ currency }} / traffic
                                </h6>
                            </div>
                        </div>
                    </div>

                    <div class="form-group col-12 text-center bold">
                        <button
                            @click="createOrder()"
                            :class="['btn btn-block btn-dark-blue btn-lg btn-rounded bold']"
                            type="submit"
                        >
                            Tạo tiến trình
                        </button>
                    </div>
                </div>
            </div>
            <div class="tab-pane fade" :class="panel === 'logs' ? 'show active' : ''">
                <TrafficLogs :panel="panel" :type="type"></TrafficLogs>
            </div>
        </div>
    </div>
</template>
<script>
import { catchError } from "@/helpers"
import { createOrder } from "@/api/services/traffics"
import { imageUpload } from "@/api/support"
import TrafficLogs from "./traffic-logs"
import TrafficsNote from "./traffic-note.vue"

export default {
    name: "traffic-order",
    components: { TrafficsNote, TrafficLogs },
    data() {
        return {
            type: "",
            panel: "order",
            files: [],
            url: "",
            referral: "",
            phone: "",
            search_link: "",
            keywords: "",
            quantityPerDay: 50,
            quantity: 500,
            minPerDay: 30,
            min: 500,
            timeOnSite: null,
            group_type: "seo"
        }
    },
    computed: {
        currency() {
            return this.$store.state.site.site.currency
        },
        packages() {
            let tool = this.$store.state.tools.tools.find(tool => tool.name === "Seo Traffic")
            const prices = tool.prices.filter(price => price.group == this.group_type)
            return prices
        },
        price() {
            const result = this.packages.find(item => item.package_name === this.timeOnSite)
            return result?.price || 0
        },
        totalPrice() {
            return Math.ceil(this.price * this.quantity)
        },
        router() {
            return this.$route.path
        }
    },
    watch: {
        router(value) {
            this.panel = "order"
            if (value.includes("backlink")) {
                this.type = "backlink"
                this.group_type = "seo"
            } else if (value.includes("redirect")) {
                this.type = "redirect"
                this.group_type = "redirect"
            } else {
                this.type = "google"
                this.group_type = "seo"
            }
            this.url = ""
            this.referral = ""
            this.phone = ""
            this.keywords = ""
            this.quantityPerDay = 50
            this.quantity = 500
            this.minPerDay = 30
            this.min = 500
            this.files = []
            this.timeOnSite = null
        }
    },
    async created() {
        if (this.$route.path.includes("backlink")) {
            this.type = "backlink"
            this.group_type = "seo"
        } else if (this.$route.path.includes("redirect")) {
            this.type = "redirect"
            this.group_type = "redirect"
        } else {
            this.type = "google"
            this.group_type = "seo"
        }
    },
    methods: {
        async createOrder() {
            const {
                url,
                files,
                search_link,
                keywords,
                quantity,
                quantityPerDay,
                minPerDay,
                min,
                price,
                timeOnSite,
                theFormat,
                referral,
                phone,
                type
            } = this

            // Validate form
            const error = {
                url: !url ? "Vui lòng nhập đường dẫn" : null,
                search_link: type === "backlink" && !search_link ? "Vui lòng nhập trang web đích chứa backlink" : null,
                // keywords: !keywords ? "Vui lòng nhập từ khóa" : null,
                quantity: quantity < min ? `Vui lòng nhập tổng số traffic lớn hơn ${theFormat(min)}` : null,
                quantityPerDay:
                    quantityPerDay < minPerDay ? `Vui lòng nhập số traffic/ngày lớn hơn ${theFormat(minPerDay)}` : null,
                price: !price ? "Vui lòng chọn gói onsite" : null
                // image: files.length < 1 ? "Vui lòng thêm hình ảnh mô tả" : null
            }

            if (type != "redirect") {
                error.keywords = !keywords ? "Vui lòng nhập từ khóa" : null
                error.image = files.length < 1 ? "Vui lòng thêm hình ảnh mô tả" : null
            }

            const errorText = Object.values(error).filter(item => !!item)
            if (errorText.length > 0) {
                this.$toastr.error(errorText.join("<br >"))
                return
            }

            // Get image url
            let imagesUrl = ""
            if (files.length > 0) {
                let formData = new FormData(this.form)
                let file = null
                for (var i = 0; i < files.length; i++) {
                    file = files[i]
                }
                formData.append("file", file)
                let dataImage = await imageUpload(formData, formData)
                if (!dataImage.url) {
                    this.$swal("Lỗi", catchError(dataImage), "error")
                    return
                } else {
                    imagesUrl = dataImage.url
                }
            }
            let time_on_site = timeOnSite
            const timeOnSiteArr = timeOnSite.match(/\d+/g)
            if (timeOnSiteArr.length > 0) {
                time_on_site = parseInt(timeOnSiteArr[0])
            }
            // Create order
            const postData = {
                link: url,
                search_link,
                keywords: !keywords ? url : keywords,
                daily_traffic: quantityPerDay,
                time_on_site: time_on_site,
                image: imagesUrl,
                quantity,
                referral: referral || "",
                phone: phone || "",
                type
            }
            const response = await createOrder(postData)
            if (!response) {
                this.$toastr.error("Đã có lỗi xảy ra, vui lòng thử lại sau")
                return
            }
            if (response.success) {
                this.$toastr.success(response.message || "Tạo tiến trình thành công !")
            } else {
                this.$toastr.error(catchError(response))
            }
        },
        handleFilesUpload() {
            let uploadedFiles = this.$refs.files.files
            for (var i = 0; i < uploadedFiles.length; i++) {
                uploadedFiles[i].url = URL.createObjectURL(uploadedFiles[i])
                this.files.push(uploadedFiles[i])
            }
        },
        removeFile(key) {
            this.files.splice(key, 1)
        },
        async handleBlurQuantityPerDay(e) {
            const value = +e.target.value.replace(/,/gi, "")
            const { minPerDay, theFormat } = this
            if (value < minPerDay) {
                await this.$swal.fire({
                    title: "Thông báo",
                    type: "warning",
                    confirmButtonText: "Đóng",
                    reverseButtons: true,
                    html: `Vui lòng không nhập số lượng thấp hơn ${theFormat(minPerDay)}`
                })
                this.quantityPerDay = minPerDay
            }
        },
        async handleBlurQuantity(e) {
            const value = +e.target.value.replace(/,/gi, "")
            const { min, theFormat } = this
            if (value < min) {
                await this.$swal.fire({
                    title: "Thông báo",
                    type: "warning",
                    confirmButtonText: "Đóng",
                    reverseButtons: true,
                    html: `Vui lòng không nhập số lượng thấp hơn ${theFormat(min)}`
                })
                this.quantity = min
            }
        },
        handleChangeQuantity(e) {
            const value = +e.target.value.replace(/,/gi, "")
            if (isNaN(value)) {
                this.quantity = this.min
            } else {
                this.quantity = value
            }
            this.$forceUpdate()
        },
        handleChangeQuantityPerDay(e) {
            const value = +e.target.value.replace(/,/gi, "")
            if (isNaN(value)) {
                this.quantityPerDay = this.minPerDay
            } else {
                this.quantityPerDay = value
            }
            this.$forceUpdate()
        },
        theFormat(number) {
            return number
                ? Math.round(Number(number))
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                : 0
        },
        handleShowPanel: async function(panel) {
            this.panel = panel
        }
    }
}
</script>
