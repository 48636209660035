import { request } from "@/utils/request"

export function createOrder(data) {
    return request({
        url: "api/traffic/create",
        method: "post",
        data
    })
}

export function getLogsOrder(params) {
    return request({
        url: "api/traffic/list" + (params ? params : ""),
        method: "get"
    })
}

export function getListReportByAdsId(params) {
    return request({
        url: "api/traffic/get-report" + (params ? params : ""),
        method: "get"
    })
}

export function reCheckAds(data) {
    return request({
        url: "api/traffic/recheck",
        method: "post",
        data
    })
}
